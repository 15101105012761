/* You can add global styles to this file, and also import other style files */
@import "~animate.css/animate.min.css";

@font-face {
  font-family: "tavuel-font-regular";
  src: url(/assets/Fonts/Montserrat-Regular.otf) format("truetype");
}

@font-face {
  font-family: "tavuel-font-semi-bold";
  src: url(/assets/Fonts/Montserrat-SemiBold.otf) format("truetype");
}

@font-face {
  font-family: "tavuel-font-bold";
  src: url(/assets/Fonts/Montserrat-ExtraBold.otf) format("truetype");
}

@font-face {
  font-family: "tavuel-font";
  src: url(/assets/Fonts/AristotelicaSmallCaps-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(/assets/Fonts/Material-Icons.woff2) format("woff2");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  background: #000000;
  overflow-x: hidden;
  &.modal-active {
    overflow: hidden;
  }
}

app-sidenav {
  display: flex;
}

mat-drawer-container {
  flex: 1 1 auto;
}

// styles of scrollbar
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-thumb {
  background: #bdbdbd99;
  border-radius: 25px;
}

.page {
  background-color: #000000;
}

.svg {
  font-family: "tavuel-font";
}

.z-2 {
  z-index: 2;
}
.space-around {
  display: flex !important;
  justify-content: space-around !important;
}
.container-sections {
  display: flex;
  flex-direction: column;
  gap: 5vw;
}
.footer {
  bottom: 0;
  position: relative;
  z-index: 2;
  padding: 50px 0px;
  width: 100%;

  a {
    z-index: 2;
    color: #4c4c4c;
    cursor: pointer;
    text-decoration: none;
    margin: 10px;
    text-align: center;
    transition: all 0.8s ease-in-out;
    border-radius: 50%;
    &:hover {
      color: #afde02;
    }
  }

  .menu-icon {
    font-size: 40px !important;
    cursor: pointer;
    margin: 10px;
    height: 28px !important;
    width: 28px !important;
  }

  .footer-container-w-xs {
    width: 65%;

    .location-footer {
      margin-left: 100px;
      text-align: start !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: auto;

      .location-footer-title {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        color: #fff;
        font-family: "tavuel-font-semi-bold";
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        text-align: initial !important;
      }
      a {
        text-decoration: none !important;
        font-family: "tavuel-font-regular" !important;
        margin: 0px !important;

        p {
          text-align: initial !important;
          margin-top: 10px !important;
          margin-bottom: 10px !important;
          font-size: 16px !important;
          font-style: normal !important;
          font-weight: 400 !important;
          line-height: normal !important;
          color: #fff !important;
          font-family: "tavuel-font-regular" !important;
        }
      }
    }

    .contact-footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: auto;

      p:first-child {
        display: block !important;
        color: #fff;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        font-size: 20px !important;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        font-family: "tavuel-font-semi-bold";
      }
      p {
        display: block !important;
        color: #fff;
        text-align: start !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        font-family: "tavuel-font-regular";
      }
    }
  }

  .footer-container-w-xsL {
    float: right;
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .privacy-policy {
      text-align: center;
      color: #fff;
      font-family: "tavuel-font-regular";
      margin-top: 20px !important;
      margin-bottom: 10px !important;

      a {
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        font-family: "tavuel-font-regular";
      }
    }
    .footer-year {
      color: #fff;
      text-align: left !important;
      margin-block: 0px !important;
      font-family: "tavuel-font-regular" !important;
      font-size: 14px !important;
      font-weight: 400 !important;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  /* Firefox */
}

ngx-dropzone-label {
  z-index: 9 !important;
}

.headers-align .mat-expansion-panel-header-description {
  justify-content: space-between;
  align-items: center;
}

.top {
  margin-top: 135px;
}

.white {
  color: white;
}
.text-green {
  color: #afde02 !important;
}
.custom-dialog .mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 16px !important;
}
.custom-dialog .mdc-dialog__container {
  display: block !important;
}
.row {
  flex-flow: row wrap;
  box-sizing: border-box;
  display: flex;
  place-content: stretch center;
  align-items: stretch;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 3.9s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fast {
  -webkit-animation-duration: 0.8s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

@keyframes slidein {
  0% {
    margin-left: 1500px;
    position: relative;
  }
  85% {
    margin-left: 10%;
    padding: 5em;
    position: relative;
  }
}
.note {
  animation-direction: alternate;
  animation-name: slidein;
  animation-duration: 1s;
  display: block;
}

@keyframes zoomIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes zoomOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes unfoldOut {
  0% {
    transform: scaleY(1) scaleX(1);
  }
  50% {
    transform: scaleY(0.005) scaleX(1);
  }
  100% {
    transform: scaleY(0.005) scaleX(0);
  }
}
.zoomIn {
  animation-direction: alternate;
  animation-name: zoomIn;
  animation-duration: 1s;
  display: block;
}

@keyframes unfoldIn {
  0% {
    transform: scaleY(0.005) scaleX(0);
  }
  50% {
    transform: scaleY(0.005) scaleX(1);
  }
  100% {
    transform: scaleY(1) scaleX(1);
  }
}

.btn-show-more {
  border-radius: 7px;
  border: 1.5px solid #fff;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  color: #fff;
  display: flex;
  width: 90px;
  height: 36px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: "tavuel-font-semi-bold";
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.container {
  display: flex;
}

.list {
  flex-direction: column;
}

.grid {
  flex-wrap: wrap;
}

.vertical-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.container-center {
  justify-content: center;
}
.center {
  text-align: center;
  margin: 0 auto;
}

.justify-content-center {
  justify-content: center;
}

.actions {
  justify-content: space-around;
}

.content-right {
  justify-content: flex-end;
  text-align: right;
}

.content-left {
  justify-content: flex-start;
  text-align: left;
}

.content-justify {
  justify-content: flex-center;
  text-align: justify;
}

each(range(1%, 100%, 1), {
  .w-@{index} {
    width: @value;
  }
});

.no-click-style {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-tap-highlight-color: rgba(0, 0, 0, 0);
}

.btn {
  font-weight: bold;
  background: transparent;
  border-radius: 7px;
  font-size: 2vh;
  cursor: pointer;
  outline: none;
  padding: 5px 12px;
  height: 40px;
  border: none;
}

.btn-blue {
  background-color: #172d80;
  color: white;
  width: 10%;
}

.btn-lightgray {
  background-color: #cecece;
  color: black;
  width: 10%;
}

.btn-red {
  background-color: #bf4040;
  color: white;
  width: 100px;
}

.btn-green {
  background-color: #5da02d;
  color: white;
  width: 100px;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.modal-active {
  overflow: hidden;
}

.social-background {
  width: 97%;
  background-color: #000000;
  height: 100px;
  position: relative;
  transition: all 2s ease-in;
}

.mat-mdc-menu-content {
  padding: 0px 0 !important;
}

.nav-bar {
  width: 100%;
  position: relative;
  z-index: 15;
}

@keyframes slideInDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

.sticky-navbar {
  animation: slideInDown 0.5s ease-in-out;
  position: fixed;
  top: 20px;
  width: 100%;
}

.nav-bar-logo {
  margin-left: 80px;
  width: 100px;
  height: 69px;
  position: absolute;
  cursor: pointer;
  left: 0px;
  z-index: 11;
  margin-top: 20px;
  transition: all 2s ease-in;
}

.navbar-home {
  display: flex;
  gap: 1px 100px;
  flex-wrap: wrap;
  justify-content: center;
}

.nav-bar-page {
  width: 100%;
  height: 100px;
  position: fixed !important;
  bottom: 5px;
  z-index: 15;
  .nav-bar-phone {
    width: 96%;
    background-color: #0c0c0c;
    height: 80px;

    .social-menu-phone {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      .icons {
        display: flex;
        gap: 35px;
        flex-wrap: wrap;
        justify-content: center;
        height: 100%;
      }

      .icon-menu {
        height: 85%;
        width: auto;
        .line-button {
          display: none;
          border-radius: 10px;
          background: #afde02;
          width: 100%;
          position: relative;
          height: 5px;
        }

        .button-navbar-phone {
          position: relative;
          width: 70px;
          height: 100%;
          border: none;
          background: transparent;
          position: relative;
          cursor: pointer;
          z-index: 1;
          justify-content: center;
          display: flex;
          align-items: center;
        }

        .button-navbar-phone img {
          width: 40px;
          height: 40px;
        }

        &:hover {
          .line-button {
            display: block;
          }

          .button-navbar-phone {
            background: linear-gradient(to bottom, #afde02, #0c0c0c);
            clip-path: polygon(15% 0, 85% 0, 100% 100%, 0 100%);

            img {
              position: relative;
              top: -5px;
            }
          }
        }
      }
    }
  }
}

.background-services-phone {
  background: #393939e6 !important;
  color: white !important;
}

.navigation-pannel {
  position: absolute;
  bottom: 0px;
  width: 95%;
  height: 100%;
  align-content: center;
  justify-content: end;
  .social-menu {
    width: 100%;
    padding: 0px;
    display: flex;
    top: 0;
    margin-top: 3px;
    height: 60px;
    position: absolute;
  }
  .navigation-button {
    font-family: "tavuel-font-semi-bold";
    color: #fff;
    background: transparent;
    font-size: 20px;
    padding: 5px 5px 0px 5px;
    border: none;
    font-weight: 600;
    outline: none;
    cursor: pointer;
    position: relative;
    transition: all 0.8s ease-in-out;
    border-bottom: 2px solid transparent;
    height: 47px;
    &:hover {
      color: #afde05;
      border-bottom: 2px solid #afde05;
      background: rgba(0, 0, 0, 0.068);
    }
  }

  .dropdown {
    display: inline-block;
    position: relative;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    width: 100%;
    overflow: none;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    top: 48px;
    left: 1px;
    padding: 10px;
  }

  .dropdown:hover .dropdown-content {
    display: block;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.08);
    background: #393939e6 !important;
    box-shadow: 4px 7px 15px 0px rgba(0, 0, 0, 0.08),
      5px -5px 12px 0px rgba(0, 0, 0, 0.05) inset,
      5px -5px 12px 0px rgba(0, 0, 0, 0.05) inset,
      -1px 0px 2px 0px rgba(201, 201, 201, 0.1) inset;
    backdrop-filter: blur(75px);
    width: 260px;
  }

  .dropdown-content a {
    display: block;
    color: var(--textos, #fff);
    padding: 5px;
    text-decoration: none;
  }

  .dropdown-content a:hover {
    transition-duration: 0.8s;
    color: #afde05;
  }
}

.mat-mdc-menu-item .mdc-list-item__primary-text {
  font-family: "tavuel-font-regular" !important;
}

.mat-menu-services {
  background: rgba(46, 43, 43, 0.56) !important;
  border: 1px solid rgba(255, 255, 255, 0.08) !important;
  box-shadow: 4px 7px 15px 0px rgba(0, 0, 0, 0.08),
    5px -5px 12px 0px rgba(0, 0, 0, 0.05) inset,
    5px -5px 12px 0px rgba(0, 0, 0, 0.05) inset,
    -1px 0px 2px 0px rgba(201, 201, 201, 0.1) inset !important;
  margin-bottom: 5px !important;
  border-radius: 8px !important;
}

.mat-menu-projects {
  border-radius: 4px !important;
  border: 1px solid rgba(255, 255, 255, 0.08) !important;
  background: rgba(0, 0, 0, 0.9) !important;
  box-shadow: 4px 7px 15px 0px rgba(0, 0, 0, 0.08),
    5px -5px 12px 0px rgba(0, 0, 0, 0.05) inset,
    5px -5px 12px 0px rgba(0, 0, 0, 0.05) inset,
    -1px 0px 2px 0px rgba(201, 201, 201, 0.1) inset !important;
}

.contact {
  border: 0px;
  border-radius: 20% 0% 20% 0%;
}

.wrapper {
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(3, 0fr);
  grid-gap: 5px;
}

.wrapper-projects {
  display: grid;
  grid-template-columns: repeat(3, 0fr);
  grid-gap: 100px;
}

.home-container {
  padding-top: 60px;
  position: relative;
  height: 450px;
}

.who-container {
  height: 600px;
  position: relative;
}

.clients-container {
  height: auto;
  z-index: 10;
}

.card-tavuel {
  position: relative;
  width: 500px;
  height: 290px;
  border-radius: 35px;
  padding: 30px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  z-index: 9;
}

.card-tavuel::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 35px;
  background: rgba(46, 43, 43, 0.56);
  box-shadow: 4px 7px 15px 0px rgba(0, 0, 0, 0.08),
    5px -5px 12px 0px rgba(0, 0, 0, 0.05) inset,
    5px -5px 12px 0px rgba(0, 0, 0, 0.05) inset,
    -1px 0px 2px 0px rgba(201, 201, 201, 0.1) inset;
  z-index: -1;
}

.ml-30 {
  margin-left: 30px;
}
.ml-10 {
  margin-left: 10%;
}
.text-card-tavuel {
  z-index: 6;
  position: absolute;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 25px;

  h1 {
    color: var(--Textos, #fff);
    font-family: "tavuel-font-semi-bold";
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0px 0px 8px 0px;
  }

  p {
    color: var(--Textos, #fff);
    font-family: "tavuel-font-regular";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 8px 0px 12px 0px;
  }
}

.team-container {
  display: flex;
  width: 200px;
  height: fit-content;
  padding: 30px 10px 16px 10px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 18px;
  border: 1px solid var(--Textos, #fff);
  background: var(--Fondo, #000);
  transition: all 1s ease-in-out;
  z-index: 9;

  .team-info {
    height: fit-content;

    .info-container {
      position: relative;
      margin-top: -90px;
      text-align: start;
      height: fit-content;

      .personal-info {
        h3 {
          color: var(--Textos, #fff);
          font-family: "tavuel-font-bold";
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          margin: 0px 0px 8px 0px;
        }

        h4 {
          color: var(--Textos, #fff);
          font-family: "tavuel-font-semi-bold";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin: 0px 0px 8px 0px;
        }
      }

      .text {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        color: var(--Textos, #fff);
        font-family: "tavuel-font-regular";
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        transition: all 1.5s ease-in-out;
        margin-bottom: 0px;
        max-height: 6.5em;
      }
    }
  }

  &:hover {
    transform: scale(1.02);
    cursor: pointer;
    .team-info .info-container .text {
      max-height: 40em;
    }
  }
}

.avatar {
  position: relative;
  top: -100px;
  background-color: #e2dfdf;
  width: 90px !important;
  height: 90px !important;
  border-radius: 50%;

  .list-avatar {
    height: 80px;
    width: 70px;
    background-size: contain !important;
    cursor: pointer;
  }
}

.clients-text {
  height: 80px;
  h1 {
    color: var(--Acento, #afde02);
    font-family: "tavuel-font-semi-bold";
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.columns-filter {
  display: grid;
  grid-template-columns: repeat(1, 0fr);
  grid-gap: 20px;
}

.clients-button {
  font-size: 20px !important;
  height: 30px;
  width: 35px;
  border: none;
  outline: none;
  background-color: transparent;
}

.vertical-center-filter {
  display: flex;
  align-items: center;
}

.clients-filter {
  text-align: start;
  p {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-family: "tavuel-font-semi-bold";
  }
  mat-radio-group {
    mat-radio-button {
      text-align: start !important;
      color: #fff !important;
      font-size: 15px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: normal !important;
      font-family: "tavuel-font-regular";
    }
  }
}

.container-img-projects {
  width: 400px;
  padding: 8px;
  cursor: pointer;
}

.image-client {
  width: auto;
  height: auto;
  position: relative;
  img {
    border-radius: 10px;
  }
}

.client-phone {
  position: absolute;
  top: 55px;
  right: -18px;
  max-width: 50%;
  height: auto;
  border: 3px solid #000011;
  background-color: #000000;
}

.client-pc {
  width: 370px;
  max-width: 100%;
  height: auto;
}

.container-image-without-link {
  justify-content: center;
  width: 370px;
  height: 258px;
  position: relative;
  border-radius: 10px;
  background-color: #fff;

  .image-without-link {
    width: 200px;
  }
}

.container-g360 {
  width: 370px;
  height: 258px;
  position: relative;
  border-radius: 10px;
  background-color: #fff;

  .content-g360 {
    height: 100%;
  }
  img {
    width: 200px !important;
    height: 100px !important;
  }
}

.vertical-center-projects {
  justify-content: center;
  align-items: center;
}

.projects-header {
  display: flex !important;
  justify-content: space-between !important;
  h1 {
    margin: 0 !important;
  }
}
.client-text {
  width: auto;
  padding: 0px 15px 15px 15px;
  p {
    height: 25px;
    margin: 20px 0px 23px 0px;
    color: #fff;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: start;
    font-family: "tavuel-font-semi-bold";
  }

  a {
    display: flex;
    width: 72px;
    height: 20px;
    padding: 10px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    border: 1.5px solid #fff;
    text-decoration: none;
    color: #fff;
    font-family: "tavuel-font-bold";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    transition: all 0.5s ease-in-out;
    top: 0px;
    position: relative;
    &:hover {
      border: 1px solid var(--Acento, #afde02);
      color: #afde02;
    }
  }

  .client-description {
    height: auto !important;
    margin: 0px !important;
    font-size: 15px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal;
    font-family: "tavuel-font-regular" !important;
  }

  .laconfi-description {
    text-align: start;
    font-size: 15px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    font-family: "tavuel-font-regular" !important;
    margin: 0px !important;
    a {
      display: contents !important;
      margin: 0px !important;
      font-size: 15px !important;
      text-decoration: none !important;
      color: #5da02d !important;
      border: none !important;
      padding: 0px !important;
      font-family: "tavuel-font-regular";
    }
  }

  .client-text-link {
    justify-content: start !important;
    height: 65px !important;
  }
}
.team-container.active {
  transform: none;
}

.team-info .info-container .text.active {
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  max-height: 6.5em !important;
}

.wrapperCard {
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(2, 0fr);
  grid-gap: 90px 25px;
}

.btn-contact {
  display: flex;
  width: 204px;
  height: 64px;
  padding: 10px 20px 10px 20px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: transparent;
  border-radius: 10px;
  border: 1px solid var(--textos, #fff);
  color: var(--textos, #fff);
  font-size: 20px;
  transition: all 0.5s ease-in-out;
  font-family: "tavuel-font-semi-bold" !important;
  cursor: pointer;
  &:hover {
    border: 2px solid #fff;
    background: #a9a9a9;
  }
}

.btn-contact.clicked {
  border: 2px solid #394458;
  background: #ebebeb;
  color: #000;
}

.btn-container-send-request {
  height: 100px !important;
  display: flex;
  justify-content: flex-end;
}

.btn-send-request {
  font-family: "tavuel-font-semi-bold";
  box-shadow: 0px 0px 8px -4px #fff;
  border-radius: 10px;
  border: 2px solid white;
  background: transparent;
  font-size: 18px;
  width: 200px;
  height: 64px;
  color: #fff;
  margin-top: 10%;
  cursor: pointer;
  text-align: center;
  transition: all 0.8s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
}

#container {
  perspective: 40px;
}

.contact-us-container {
  .link {
    position: absolute;
    left: 100px;
    width: 307px;
    height: 47px;
    border-radius: 4px;
    background-color: #060606;
    margin-top: 70px;
    z-index: 14;
    justify-content: space-evenly !important;
    a {
      font-family: "tavuel-font-regular";
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-decoration: none;
      color: #fff;
    }
    .line {
      width: 20%;
      border-top: 3px solid #808080;
    }
  }
  .img-contact {
    max-width: 100%;
    width: 100%;
  }
  .principal-form {
    margin-top: 8vh;
    max-height: 790px;

    .form-container {
      h1 {
        color: #fff !important;
        font-family: "tavuel-font-semi-bold";
        font-size: calc(1em + 1vw);
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: left;
        margin-left: 3.7%;
      }
      .fixed-header {
        background-color: #060606;
        position: sticky;
        top: 0;
        padding-block: 10px;
        z-index: 1;
      }

      width: 75%;
      position: relative;
      background: #060606;
      border-radius: 17px;
      padding: 0px 40px 50px 40px;

      .date-label {
        p {
          font-family: "tavuel-font-regular";
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-decoration: none;
          color: #fff;
          align-items: flex-start;
          margin: 5px 0;
        }
      }

      .card-form {
        position: relative;
        left: -15px;
      }

      .card-form-rigth {
        position: relative;
        top: 44px;
      }

      .input-field {
        box-sizing: border-box;
        font-family: "tavuel-font-regular";
        box-shadow: 0px 0px 8px -4px #fff;
        border-radius: 4px;
        border: 2px solid white;
        background: transparent;
        font-size: 17px;
        height: 46px;
        margin: 8px 0;
        color: #fff;
        padding-left: 10px;
        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: #fff;
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          color: #fff;
        }
        &:-ms-input-placeholder {
          /* IE 10+ */
          color: #fff;
        }
        &:-moz-placeholder {
          /* Firefox 18- */
          color: #fff;
        }
      }
      .checkbox-field {
        text-align: left;
        font-family: "tavuel-font-regular";
        background: transparent;
        font-size: 17px;
        height: 45px;
        margin: 8px 0;
        color: #fff;
        padding-left: 8px;

        border: 2px solid #fff;
        border-radius: 4px;
        border: 0;
        outline: 2px solid;
        border-right: 16px solid transparent;
      }

      .invalid-input {
        border: 1px solid red !important;
      }
      .invalid-select {
        outline: none;
        outline: 2px solid red !important;
      }
      .invalid:last-child {
        cursor: not-allowed;
      }
      .valid {
        cursor: pointer;
        border: 2px #afca21 solid !important;
        color: #afca21 !important;
      }
      .textarea-field {
        font-family: "tavuel-font-regular";
        box-shadow: 0px 0px 8px -4px #fff;
        border-radius: 10px;
        border: 2px solid #fff;
        background: transparent;
        height: 140px;
        margin: 8px 0;
        padding-left: 10px;
        color: #fff;
        font-size: 17px;
        padding-top: 8px;
        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: #fff;
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          color: #fff;
        }
        &:-ms-input-placeholder {
          /* IE 10+ */
          color: #fff;
        }
        &:-moz-placeholder {
          /* Firefox 18- */
          color: #fff;
        }
      }
      .budget {
        height: 90px;
        margin: 5px 20px;
        .budget-label {
          display: flex;
          justify-content: flex-start;
          text-align: start;
          label {
            font-family: "tavuel-font-regular";
            font-size: 17px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: #fff;
          }
        }

        .mat-slider {
          color: #fff !important;
          width: 90% !important;
        }
      }

      .date-input-field {
        border-radius: 4px;
        border: 1px solid var(--textos, #fff);
        display: flex;
        color: #fff;
        background-color: transparent;
        padding: 16px 16px 16px 10px;
        align-items: flex-start;
        gap: 10px;
        margin: 5px 0;
      }
      .mat-form-field-appearance-outline .mat-form-field-outline {
        color: red !important;
        border: 1px solid #000;
      }
    }
  }
}

.mdc-list-item-background {
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #fff !important;
  padding-right: 20px !important;
  font-family: "tavuel-font-regular" !important;
}

.cdk-overlay-container {
  z-index: 15 !important;
}

.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled:not(:checked)
  + .mdc-radio__background
  .mdc-radio__outer-circle {
  border-color: #fff !important;
}

.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled:checked
  + .mdc-radio__background
  .mdc-radio__outer-circle {
  border-color: #fff !important;
}

.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled
  + .mdc-radio__background
  .mdc-radio__inner-circle {
  border-color: #afde02 !important;
}

.mat-mdc-icon-button svg {
  color: #fff !important;
}

.mat-mdc-form-field-flex {
  align-items: center !important;
}

.mat-mdc-form-field {
  color: #fff !important;
  margin-top: 8px !important;
  font-size: 17px !important;
  font-family: "tavuel-font-regular" !important;
  height: 55px !important;
}

.mat-date-range-input-separator {
  color: #fff !important;
  border-color: transparent;
}

.mdc-floating-label--float-above {
  margin-top: 4px !important;
}

text {
  font-size: 90px;
  transition: font-size 0.6s ease-out;
  font-weight: 900;
}

#content-video {
  margin-top: calc(15% + 20px);
  margin-bottom: 5%;
}

.contain {
  background-size: contain !important;
  background-repeat: no-repeat;
}

.services-container {
  position: relative;
  height: 100% !important;
  padding: 0px 0px 25px;
  overflow: hidden;

  .services-content {
    padding-top: 150px;
    position: relative;

    .services-header {
      position: relative;
      height: 300px;
      z-index: 9;
      .text-services {
        position: relative;
        text-align: start !important;
        z-index: 9;
        p {
          color: var(--Textos, #fff);
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          font-family: "tavuel-font-regular";
        }

        h1 {
          font-family: "tavuel-font-semi-bold";
          color: var(--Textos, #fff);
          font-size: 36px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }

      .btn-services {
        margin-top: 10px;
        z-index: 9;
        width: 195px;
        height: 64px;
      }
    }

    .info-service-container {
      position: relative;
      margin-bottom: 10%;
      height: auto;
      z-index: 9;

      .container-img {
        position: relative;
        justify-content: center;
        width: 400px;
        padding: 8px;
        cursor: pointer;

        .service-card-info {
          position: relative;
          display: flex;
          width: 330px;
          height: 220px;
          padding: 20px 30px 32px 30px;
          flex-direction: column;
          align-items: flex-start;
          gap: 15px;
          border-radius: 18px;
          border: 1px solid rgba(255, 255, 255, 0.08);
          transition: all 0.8s ease-in-out;
          z-index: 9;

          h2 {
            font-family: "tavuel-font-semi-bold";
            text-align: start;
            color: var(--Textos, #fff);
            font-size: 17px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin: 8px 8px 0px 0px !important;
          }

          p {
            font-family: "tavuel-font-regular";
            margin: 0px !important;
            text-align: start;
            color: var(--Textos, #fff);
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          &:hover {
            transform: scale(1.05);
          }
        }

        .service-card-info::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 18px;
          background: rgba(46, 43, 43, 0.56);
          box-shadow: 4px 7px 15px 0px rgba(0, 0, 0, 0.08),
            5px -5px 12px 0px rgba(0, 0, 0, 0.05) inset,
            5px -5px 12px 0px rgba(0, 0, 0, 0.05) inset,
            -1px 0px 2px 0px rgba(201, 201, 201, 0.1) inset;
          z-index: -1;
        }
      }
    }
  }
}

.snackbar-error {
  border: 3px solid rgb(207, 3, 3);
  background-color: rgb(207, 3, 3) !important;
  color: white !important;
  border-radius: 10px !important;
  font-weight: bold;
  .mat-simple-snackbar-action {
    color: white;
  }
}

.snackbar {
  border: 3px solid rgb(81, 207, 3);
  background-color: rgb(81, 207, 3) !important;
  color: black !important;
  border-radius: 10px !important;
  font-weight: bold;
  .mat-simple-snackbar-action {
    color: black;
  }
}

.contact {
  a {
    text-decoration: none;

    p {
      font-weight: bold;
    }
  }
  a:hover {
    text-decoration: white underline !important;
  }
}

.mt-20 {
  margin-top: 20px;
}

option {
  background: #292929 !important;
}

.cvSection-container {
  .cvSection-content {
    .container-cvSection {
      display: flex;
      flex-direction: column;
      align-items: center !important;
      justify-content: center !important;
    }
    .cvSection-text {
      width: 100%;
      display: grid;
      justify-content: center;
      padding-block: 20px;
      margin-block: 30px;
      color: #fff;
      h1 {
        color: var(--Textos, #fff);
        font-family: "tavuel-font-semi-bold";
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: start;
      }
      .cvSection-info {
        margin-left: 2.1%;
      }
      p {
        font-family: "tavuel-font-regular";
        color: var(--Textos, #fff);
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: start;
      }
    }
    .invalid-input {
      border: 1px solid red !important;
    }
    .invalid {
      cursor: not-allowed;
    }
    .valid {
      cursor: pointer;
      border: 2px #afca21 solid !important;
      color: #afca21 !important;
    }
    .select-wrapper {
      height: 42px;
      border: 2px #fff solid;
      margin-top: 5px;
      margin-bottom: 5px;
      border-radius: 4px;
      box-sizing: border-box;
      box-shadow: 0px 0px 8px -4px #fff;
    }

    .select {
      padding-left: 10px;
      box-sizing: border-box;
      font-family: "tavuel-font-regular";
      border: none;
      border-radius: 4px;
      background: transparent;
      font-size: 18px;
      height: 40px;
      color: #fff;
      border-right: 8px solid transparent;
      &::placeholder {
        padding-left: 10px !important;
      }
      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #fff;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: #fff;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: #fff;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: #fff;
      }
    }

    ngx-dropzone {
      margin-top: 5px;
      margin-bottom: 5px;
      height: 115px !important;
      background-color: transparent !important;
      color: #fff !important;
      padding: 10px !important;
      border: 1px #fff solid !important;
      font-family: "tavuel-font-regular" !important;
      align-items: center !important;
      justify-content: center !important;
    }

    ngx-dropzone-preview {
      height: 80px !important;
      background-color: #333333 !important;
      color: black !important;
      border: 1px #333333 solid !important;
      ngx-dropzone-remove-badge {
        background-color: black !important;
      }
    }

    .input-field {
      box-sizing: border-box;
      font-family: "tavuel-font-regular";
      box-shadow: 0px 0px 8px -4px #fff;
      border-radius: 4px;
      border: 2px solid white;
      background: transparent;
      font-size: 18px;
      height: 40px;
      margin: 5px 0;
      margin-right: 5px !important;
      color: #fff;
      padding-left: 10px;

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #fff;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: #fff;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: #fff;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: #fff;
      }
    }

    .btn-send-cv {
      width: 200px;
      height: 65px;
      text-align: center;
      padding: 15px 10px 15px 10px;
      background: transparent;
      text-decoration: none;
      color: #fff;
      border: 1px #fff solid;
      font-size: 20px !important;
      border-radius: 10px;
      margin-top: 25px;
      font-family: "tavuel-font-semi-bold";
      transition: all 0.8s ease-in-out;
      &:hover {
        transform: scale(1.05);
      }
    }
  }
}

.services-container-home {
  height: auto;
  h1 {
    color: var(--Acento, #afde02);
    font-family: "tavuel-font-semi-bold";
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0px 0px 70px 70px;
  }

  .wrapper-services {
    display: grid;
    grid-template-columns: repeat(3, 0fr);
    grid-gap: 30px 20px;
  }

  .container-img {
    justify-content: center;
    width: 400px;
    padding: 8px;
    cursor: pointer;
    position: relative;
    z-index: 9;
    .services-card {
      width: 316px;
      height: 160px;
      padding: 34px 20px;
      align-items: flex-start;
      align-content: flex-start;
      flex-wrap: wrap;
      border-radius: 16px;
      background: #04275a;
      transition: all 0.5s ease-in-out;
      position: relative;
      gap: 10px 101px;
      &::before,
      &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
        border: 2px solid #afde02;
        transition: all 0.25s ease-out;
        border-radius: 24px;
      }
      &::before {
        background-color: #afde02;
        border-radius: 24px;
        top: -15px;
        left: -15px;
      }
      &::after {
        bottom: -15px;
        right: -15px;
      }
      &:hover {
        &::before {
          top: 15px;
          left: 15px;
        }
        &::after {
          bottom: 15px;
          right: 15px;
        }
      }

      h2 {
        font-family: "tavuel-font-semi-bold";
        color: var(--Textos, #fff);
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 0px 0px 0px 10px;
      }

      p {
        height: 100px;
        font-family: "tavuel-font-regular";
        color: var(--Textos, #fff);
        text-align: start;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .description-outsourcing {
        line-height: 1.3 !important;
      }

      .image-services {
        width: 29px;
        height: 29px;
        position: absolute;
        right: 30px;
        top: 25px;
      }
    }
  }
}

.img-top-1 {
  position: absolute;
  width: 650px;
  z-index: 5;
  left: -150px;
  top: 800px;
  transition: transform 28s ease-in-out;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 8s linear infinite;
}

.img-top-2 {
  position: absolute;
  width: 550px;
  z-index: 4;
  right: -290px;
  top: -20px;
  transition: transform 22s ease-in-out;
  -webkit-animation: spin_reverse 4s linear infinite;
  -moz-animation: spin_reverse 4s linear infinite;
  animation: spin_reverse 8s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spin_reverse {
  100% {
    -moz-transform: rotate(-360deg);
  }
}
@-webkit-keyframes spin_reverse {
  100% {
    -webkit-transform: rotate(-360deg);
  }
}
@keyframes spin_reverse {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 16px !important;
  width: auto !important;
}

.feedback-modal-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 0px 0px 20px 0px;
  height: 485px;
  width: 100%;
}

.feedback-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  background: var(--Acebto2, #04275a);
  flex: 0 0 auto;
  margin-bottom: 20px;
  padding: 20px;

  .container-close {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    .container {
      width: 90%;
      display: flex;
      justify-content: flex-end;

      button {
        font-family: "tavuel-font-regular";
        color: #fff;
        font-size: 24px;
        font-weight: 400;
        background-color: transparent;
        outline: none;
        border: none;
        cursor: pointer;
        position: absolute;
        right: 15px;
        top: 10px;
      }
    }
  }

  h1 {
    width: 100%;
    text-align: center;
    margin: 15px;
    color: #fff;
    font-family: "tavuel-font-semi-bold";
    font-size: 20px;
    font-weight: 600;
  }

  .title-feedback {
    text-align: start !important;
    margin: 0;
  }

  h2 {
    width: 100%;
    text-align: start;
    margin: 10px 0 0 0;
    color: #fff;
    font-family: "tavuel-font-regular";
    font-size: 20px;
    font-weight: 400;
  }
}

.feedback-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50%;
  gap: 20px;

  .textarea-container {
    display: flex;
    position: relative;
    width: 80%;
    justify-content: center;

    .textarea-field {
      display: flex;
      font-family: "tavuel-font-regular";
      border-radius: 4px;
      border: 1px solid #000;
      background: transparent;
      width: 100%;
      height: 160px;
      padding: 16px;
      align-items: flex-start;
      margin: 8px 0;
      color: #000;
      font-size: 17px;
      resize: none;
      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #000;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: #000;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: #000;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: #000;
      }
    }

    span {
      position: absolute;
      right: 15px;
      bottom: 15px;
      color: #292929;
      font-family: "tavuel-font-regular";
      font-size: 16px;
      font-style: normal;
      font-weight: 100;
      line-height: normal;
    }
  }

  .container-radio-buttons {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    .group {
      display: flex;
      gap: 66px;
      flex-direction: row;
    }
  }

  .mat-mdc-radio-button
    .mdc-radio
    .mdc-radio__native-control:enabled:not(:checked)
    + .mdc-radio__background
    .mdc-radio__outer-circle {
    border-color: var(--black-06, rgba(0, 0, 0, 0.6)) !important;
  }

  .mat-mdc-radio-button
    .mdc-radio
    .mdc-radio__native-control:enabled:checked
    + .mdc-radio__background
    .mdc-radio__outer-circle {
    border-color: var(--black-06, rgba(0, 0, 0, 0.6)) !important;
  }

  .mat-mdc-radio-button
    .mdc-radio
    .mdc-radio__native-control:enabled
    + .mdc-radio__background
    .mdc-radio__inner-circle {
    border-color: #04275a !important;
  }

  .mat-mdc-radio-button .mdc-radio {
    padding: 0px 5px 0px 5px !important;
  }

  .mdc-form-field > label {
    font-family: "tavuel-font-semi-bold" !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
    padding-left: 0px !important;
  }
}

.send-feedback-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  .send-feedback {
    display: flex;
    width: 230px;
    height: 50px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    gap: 10px;
    border-radius: 10px;
    border: 1.5px solid #000;
    font-family: "tavuel-font-semi-bold";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #000;
    cursor: pointer;
    transition: all 0.8s ease-in-out;

    &:hover {
      border-radius: 10px;
      border: 1.5px solid var(--Acento, #afde02);
      background: var(--Acento, #afde02);
    }
  }
}

.invalidFeedback:last-child {
  border-radius: 10px;
  border: 1.5px solid #000 !important;
  opacity: 0.5;
  background-color: transparent !important;
  cursor: not-allowed;
}

.feedback-container {
  background-color: #fff;
  padding: 0px;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  position: fixed;
  bottom: 5px;
  right: 5px;
  z-index: 15;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    4px -4px 6px 0px rgba(0, 0, 0, 0.25) inset;
  stroke-width: 1px;
  stroke: #d3d0d0;
  cursor: pointer;
  transition: all 1s ease-in-out;

  button {
    display: flex;
    justify-content: center !important;
  }
  .icon_feedback {
    width: 50px;
    height: 40px;
  }
}

.background-feedback {
  background: #afde02 !important;
  color: #000 !important;
  font-family: "tavuel-font-semi-bold" !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  border-radius: 60px;
  margin-top: 10px !important;
  min-height: 30px !important;
}

.mat-menu {
  background-color: transparent !important ;
  border-radius: 0% !important;
  box-shadow: none !important;
  border: none !important;
  position: relative !important;
  top: -30px !important;
}

.invalid-feedback {
  color: red !important;
  outline: 2px solid red !important;
}

//responsive

.mat-mdc-slider .mdc-slider__track--active_fill {
  border-color: #afde02 !important;
}

.mat-mdc-slider .mdc-slider__track--inactive {
  background-color: #afde02 !important;
}

.mat-mdc-slider .mdc-slider__thumb-knob {
  background-color: #afde02 !important;
  border-color: #afde02 !important;
}

@mobile: ~"(max-width: 767px)";
@smallMobile: ~"(max-width: 420px)";
@media (min-width: 1200px) {
  .who-container {
    display: flex;
    justify-content: space-around;
  }
}
@media (max-width: 1785px) {
  each(range(1%, 100%, 1), {
    .w-xxl-@{index} {
      width: @value;
    }
  });
}

@media (max-width: 1699px) {
  each(range(1%, 100%, 1), {
    .w-lg-@{index} {
      width: @value;
    }
  });

  .wrapper-projects {
    justify-content: center;
  }

  .container-img-projects {
    width: 320px;
  }

  .container-image-without-link {
    width: 320px;
    height: 224px;
  }

  .client-pc {
    width: 320px;
  }

  .container-g360 {
    width: 320px;
    height: 224px;
  }

  .client-text {
    p {
      font-size: 18px;
    }

    .client-description {
      font-size: 13px !important;
    }
  }

  .client-phone {
    width: 60px;
    right: -25px;
    top: 45px;
  }
}

@media (max-width: 1693px) {
  .form-container {
    width: 85% !important;
  }
}

@media (max-width: 1680px) {
  .navbar-home {
    gap: 1px 70px;
  }
}

@media screen and (max-width: 1670px) {
  .wrapper {
    grid-template-columns: repeat(2, 0fr) !important;
    grid-gap: 10px !important;
  }

  .services-container {
    .services-content {
      .services-header {
        height: 350px;
        .text-services {
          p {
            font-size: 18px !important;
          }

          h1 {
            font-size: 30px !important;
          }
        }
      }

      .info-service-container {
        .container-img {
          .service-card-info {
            width: 300px;
            height: 200px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1570px) {
  .wrapper-projects {
    grid-gap: 50px;
  }
}

@media screen and (max-width: 1540px) {
  each(range(1%, 100%, 1), {
    .w-xl-@{index} {
      width: @value;
    }
  });

  .navigation-pannel {
    .navigation-button {
      font-size: 17px;
    }
  }

  .form-container {
    width: 100% !important;
  }
}

@media screen and (max-width: 1495px) {
  .footer-container-w-xs {
    display: flex;
    width: 100% !important;
  }

  .footer-container-w-xsL {
    width: 100% !important;
    margin-bottom: 30px !important;
    padding-bottom: 30px !important;

    p {
      padding-right: 0px !important;
    }
  }
}

@media screen and (max-width: 1450px) {
  .wrapper-projects {
    grid-gap: 20px;
  }

  .client-pc {
    width: 300px;
  }

  .container-image-without-link {
    width: 300px;
    height: 210px;

    .image-without-link {
      width: 180px;
    }
  }

  .container-g360 {
    width: 300px;
    height: 210px;

    img {
      width: 190px !important;
      height: 90px !important;
    }
  }

  .client-phone {
    width: 55px;
    right: -15px;
  }

  .client-text {
    p {
      font-size: 16px;
    }

    a {
      width: 60px;
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 1440px) {
  each(range(1%, 100%, 1), {
    .w-x-@{index} {
      width: @value;
    }
  });

  .contact-us-container {
    .principal-form {
      height: auto;
      margin-top: 72px;

      .form-container {
        width: 80% !important;
        height: 580px;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0px 30px 40px 30px;

        .mat-form-field-outline-start,
        .mat-form-field-outline-end,
        .mat-form-field-outline-gap {
          border-width: 25px !important;
          border-color: #ef1010 !important;
        }

        .card-form-rigth {
          top: 0px !important;
        }
        .card-form {
          left: 0px !important;
        }

        .budget {
          height: 70px;

          .budget-label {
            label {
              margin-left: 20px;
            }
          }
        }
      }
    }
  }

  .btn-container-send-request {
    height: 60px !important;
  }

  .btn-send-request {
    margin-top: 5%;
  }
}

@media screen and (max-width: 1365px) {
  each(range(1%, 100%, 1), {
    .w-md-x-@{index} {
      width: @value;
    }
  });

  .container-img-projects {
    width: 315px;
  }

  .client-phone {
    right: -23px;
  }

  .navbar-home {
    gap: 1px 60px;
  }
}

@media screen and (max-width: 1340px) {
  .services-container-home {
    .container-img {
      width: 360px;
      .services-card {
        width: 300px;
      }
    }
  }
}

@media (max-width: 1300px) {
  each(range(1%, 100%, 1), {
      .w-md-@{index} {
        width: @value;
      }
    });
  .btn-send-request {
    margin-top: 3%;
    padding: 10px 5px 10px 5px !important;
    font-size: 15px !important;
    width: 170px !important;
    height: 50px !important;
  }

  .cvSection-container {
    .cvSection-content {
      .btn-send-cv {
        padding: 10px 5px 10px 5px !important;
        font-size: 15px !important;
        width: 170px !important;
        height: 50px !important;
      }
    }
  }

  .card-tavuel {
    height: 250px;
    padding: 25px;
  }

  .text-card-tavuel {
    h1 {
      font-size: 30px;
    }

    p {
      font-size: 17px;
      margin: 8px 0px 20px 0px;
    }
  }

  .home-container {
    height: 340px;
  }

  .who-container {
    height: 400px;
  }

  .wrapper-projects {
    grid-template-columns: repeat(2, 0fr);
  }

  .clients-text {
    h1 {
      font-size: 30px;
    }
  }

  .navbar-home {
    gap: 1px 40px;
  }
}

@media screen and (max-width: 1205px) {
  .services-container-home {
    .container-img {
      .services-card {
        height: 150px;
        gap: 20px;
        h2 {
          font-size: 18px;
        }

        p {
          font-size: 16px;
        }

        .image-services {
          top: 30px;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .contact-us-container {
    .principal-form {
      height: auto;
      margin-top: 72px;
      .form-container {
        width: 70% !important;
        padding: 0px 20px 40px 20px;

        .input-field {
          font-size: 15px;
          padding-left: 10px !important;
        }

        .textarea-field {
          font-size: 15px;
        }
        .checkbox-field {
          font-size: 15px;
        }

        .budget {
          .budget-label {
            label {
              font-size: 15px;
            }
          }
        }
      }
    }
  }

  .mat-mdc-form-field {
    font-size: 15px !important;
  }

  .navigation-pannel {
    .navigation-button {
      font-size: 15px;
    }
  }
}

@media (max-width: 1170px) {
  .services-container-home {
    .wrapper-services {
      grid-template-columns: repeat(2, 0fr);
    }

    h1 {
      font-size: 30px;
      margin: 0px 0px 35px 35px;
    }

    .container-img {
      .services-card {
        p {
          margin: 0px 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 1095px) {
  .navbar-home {
    gap: 1px 15px;
  }

  .nav-bar-logo {
    width: 75px;
    height: 50px;
    margin-top: 30px;
  }
}

@media (max-width: 1000px) {
  each(range(1%, 100%, 1), {
    .w-sm-@{index} {
      width: @value;
    }
  });

  .btn {
    font-size: 1.7vh;
  }
  .btn-blue {
    width: 30%;
  }
  .btn-lightgray {
    width: 30%;
  }

  .center-cvSection {
    justify-content: center !important;
    text-align: center !important;
    margin: 0 auto !important;
  }

  .cvSection-container {
    .cvSection-content {
      .cvSection-text {
        width: 85%;
        padding: 5px !important;

        h1 {
          font-size: 20px !important;
        }
        p {
          font-size: 14px !important;
        }
      }
      .select {
        font-size: 14px !important;
      }
      .input-field {
        font-size: 14px !important;
      }
      .btn-send-cv {
        padding: 10px 5px 10px 5px !important;
        font-size: 15px !important;
        width: 170px !important;
        height: 50px !important;
      }
    }
  }
}

@media screen and (max-width: 980px) {
  .footer {
    .menu-icon {
      font-size: 30px !important;
      width: 20px !important;
      height: 20px !important;
    }

    .footer-container-w-xs {
      .location-footer {
        margin-left: 25px !important;
        p {
          font-weight: 300;
        }
        p:first-child {
          padding-left: 0px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  each(range(1%, 100%, 1), {
    .w-s-@{index} {
      width: @value;
    }
  });

  .container-img-projects {
    width: 270px;
  }

  .client-text .client-text-link {
    height: 60px !important;
  }
  .container-image-without-link {
    width: 250px;
    height: 175px;
    .image-without-link {
      width: 130px;
    }
  }

  .client-pc {
    width: 250px;
  }

  .container-g360 {
    width: 250px;
    height: 175px;

    img {
      width: 150px !important;
      height: 50px !important;
    }
  }

  .client-phone {
    right: -18px;
    top: 28px;
  }
}

@media screen and (max-width: 915px) {
  .nav-bar-logo {
    margin-left: 50px;
  }

  .navigation-pannel {
    left: 40px;
  }

  .navbar-home {
    gap: 1px 10px;
  }

  .social-background {
    width: 100%;
  }
}

@media (max-width: 875px) {
  .img-top-1 {
    width: 400px;
  }

  .img-top-2 {
    width: 400px;
    right: -160px;
    top: 80px;
  }

  .contact-us-container {
    .principal-form {
      .form-container {
        width: 90% !important;
        .input-field {
          font-size: 13px;
          padding-left: 10px;
        }
        .textarea-field {
          font-size: 13px;
        }
        .checkbox-field {
          font-size: 13px;
        }
        .budget {
          .budget-label {
            label {
              font-size: 13px;
            }
          }
        }
      }
    }
  }

  .mat-mdc-form-field {
    font-size: 13px !important;
  }

  .nav-bar-logo {
    margin-left: 35px;
  }
}

@media screen and (max-width: 860px) {
  .wrapper {
    grid-template-columns: repeat(1, 0fr) !important;
    grid-gap: 5px !important;
  }

  .services-container {
    .services-content {
      .services-header {
        .text-services {
          p {
            font-size: 16px !important;
          }
          h1 {
            font-size: 25px !important;
          }
        }
      }

      .info-service-container {
        .container-img {
          width: 100%;
          padding: 0px 0px 15px 0px;
          .service-card-info {
            width: 480px;
            height: auto;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 820px) {
  .navigation-pannel {
    .navigation-button {
      font-size: 13px;
    }
  }
  .feedback-container {
    width: 50px;
    height: 50px;
    bottom: 120px;
    right: 15px;
    .icon_feedback {
      width: 32px;
      height: 25px;
    }
  }

  .mat-menu {
    top: -134px !important;
  }

  .background-feedback {
    font-size: 13px !important;
  }
}

@media (max-width: 790px) {
  .services-container-home {
    .container-img {
      .services-card {
        width: 273px;
        height: 150px;
        gap: 20px;
        h2 {
          font-size: 18px;
        }

        p {
          font-size: 16px;
        }

        .image-services {
          top: 30px;
        }
      }
    }
  }
}

@media (max-width: 780px) {
  .form-container {
    width: 98%;
    h1 {
      margin-left: 4.1%;
    }
  }
}

@media (max-width: 768px) {
  .footer {
    .footer-container-w-xs {
      .location-footer {
        p:first-child {
          font-size: 16px;
        }
        a {
          p {
            font-size: 11px !important;
          }
        }
      }

      .contact-footer {
        p:first-child {
          font-size: 16px !important;
        }
        p {
          font-size: 11px !important;
          text-align: start;
        }
      }
    }

    .footer-container-w-xsL {
      p {
        a {
          font-size: 16px !important;
        }
      }
    }
  }

  .navbar-home {
    gap: 1px 10px;
  }

  .nav-bar-logo {
    margin-left: 25px;
  }
}

@media screen and (max-width: 750px) {
  .services-container-home {
    .wrapper-services {
      grid-template-columns: repeat(1, 0fr);
    }

    .content-left-service {
      display: flex !important;
      justify-content: flex-start !important;
    }

    .content-right-service {
      display: flex !important;
      justify-content: flex-end !important;
    }
  }
}

@media (max-width: 730px) {
  .wrapper-projects {
    grid-template-columns: repeat(1, 0fr);
  }
  .container-img-projects {
    width: 425px;
  }

  .client-pc {
    width: 400px;
  }

  .client-phone {
    width: 75px;
    right: -30px;
    top: 60px;
  }

  .container-image-without-link {
    width: 400px;
    height: 240px;

    .image-without-link {
      width: 210px;
    }
  }

  .container-g360 {
    width: 400px;
    height: 240px;

    img {
      width: 210px !important;
      height: 40px !important;
    }
  }
  .center-s {
    justify-content: center !important;
    text-align: center !important;
    margin: 0 auto !important;
  }

  .card-tavuel {
    width: 215px;
    height: 250px;
    padding: 20px;
    gap: 15px 20px;
  }

  .card-who-container {
    display: flex;
    width: 330px !important;
    height: 210px !important;
    padding: 20px !important;
    align-items: center !important;
    align-content: center !important;
    gap: 15px 20px !important;
    flex-wrap: wrap !important;
  }

  .text-card-tavuel {
    margin-left: 15px;
    h1 {
      font-size: 16px;
    }

    p {
      font-size: 13px;
    }
  }

  .btn-contact {
    width: 127.183px !important;
    height: 45.181px !important;
    font-size: 13px;
  }

  .home-container {
    height: 310px;
    padding-top: 100px;
  }

  .who-container {
    height: 345px;
  }

  .client-text {
    p {
      height: auto;
      margin: 10px 0px 10px 0px !important;
    }
  }
}

@media (max-width: 700px) {
  .services-container {
    .services-content {
      .info-service-container {
        .container-img {
          .service-card-info {
            width: 450px;
          }
        }
      }
    }
  }

  each(range(1%, 100%, 1), {
    .w-ex-sm-@{index} {
      width: @value;
    }
  });

  .clients-text {
    h1 {
      font-size: 20px;
      margin: 0px 0px 0px 45px;
    }
  }

  .feedback-header {
    h1 {
      font-size: 16px;
    }

    .title-feedback {
      font-size: 14px !important;
    }

    h2 {
      font-size: 14px;
    }
  }

  .feedback-info {
    .mdc-form-field > label {
      font-size: 14px !important;
    }

    .textarea-container {
      width: 90%;

      .textarea-field {
        font-size: 14px;
      }
    }
    .container-radio-buttons {
      .group {
        gap: 30px;
      }
    }
  }

  .send-feedback-container .send-feedback {
    display: flex;
    width: 196px;
    height: 40px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}

@media (max-width: 650px) {
  .contact-us-container {
    .principal-form {
      .form-container {
        width: 80%;
        .budget {
          margin: 5px 0px;
        }
      }
    }
  }

  .app-logo {
    width: 60px;
  }
}

@media screen and (max-width: 580px) {
  .contact-us-container {
    .principal-form {
      .form-container {
        width: 100% !important;
      }
    }
  }
}

@media screen and (max-width: 560px) {
  .services-container {
    .services-content {
      padding-top: 100px;
      .services-header {
        .text-services {
          p {
            font-size: 14px !important;
          }
          h1 {
            font-size: 20px !important;
          }
        }
      }

      .info-service-container {
        .container-img {
          .service-card-info {
            width: 350px;
          }
        }
      }
    }
  }
}

@media (max-width: 520px) {
  .nav-bar-page {
    .nav-bar-phone {
      .social-menu-phone .icon-menu {
        .button-navbar-phone {
          width: 55px;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  each(range(1%, 100%, 1), {
    .w-xs-@{index} {
      width: @value;
    }
  });

  .description {
    width: 85%;
    margin-bottom: 20%;
  }

  .btn-contact {
    width: 140px !important;
    height: 45px !important;
    font-size: 16px;
  }

  .nav-bar-page {
    .nav-bar-phone {
      .social-menu-phone {
        .icon-menu {
          .button-navbar-phone {
            img {
              width: 30px;
              height: 30px;
            }
          }
        }
      }
    }
  }

  .clients-text {
    h1 {
      margin: 0px 0px 0px 30px;
    }
  }

  .footer {
    .footer-container-w-xs {
      .location-footer {
        margin-left: 15px !important;
      }

      .contact-footer {
        display: block !important;
        margin-left: 15px !important;
        p {
          text-align: start !important;
        }
      }
    }
  }

  .team-container {
    width: 157px;
    .team-info {
      .info-container {
        margin-top: -70px;
        height: auto;
        .personal-info {
          h3 {
            font-size: 14px;
          }

          h4 {
            font-size: 13px;
          }
        }
      }
    }
  }

  .avatar {
    top: -75px;
    width: 80px !important;
    height: 80px !important;
    .list-avatar {
      width: 60px !important;
      height: 80px !important;
    }
  }

  .center-s {
    justify-content: center !important;
    text-align: center !important;
    margin: 0 auto !important;
  }

  .wrapperCard {
    grid-gap: 90px 15px;
  }

  .container-img-projects {
    width: 325px;
  }

  .client-pc {
    width: 350px;
  }

  .client-phone {
    width: 65px;
    right: -35px;
    top: 45px;
  }

  .container-image-without-link {
    width: 330px;
    height: 225px;

    .image-without-link {
      width: 210px;
    }
  }

  .container-g360 {
    width: 330px;
    height: 225px;

    img {
      width: 210px !important;
      height: 40px !important;
    }
  }

  .projects-search {
    width: 30px;
  }
}

@media (max-width: 475px) {
  .feedback-info {
    .container-radio-buttons {
      .group {
        gap: 20px;
      }
    }
    .textarea-container {
      width: 90%;
    }
  }
}

@media (max-width: 460px) {
  .card-who-container {
    width: 300px !important;
    padding: 10px 20px 10px 20px !important;
  }

  .card-tavuel {
    height: 220px;
  }
  .center-xs {
    justify-content: center !important;
    text-align: center !important;
    margin: 0 auto !important;
  }
}

@media screen and (max-width: 445px) {
  .services-container {
    .services-content {
      .info-service-container {
        .container-img {
          .service-card-info {
            width: 250px;
            padding: 20px;
            gap: 5px;
            p {
              font-size: 12px !important;
            }

            h2 {
              font-size: 12px !important;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 431px) {
  .services-container-home {
    h1 {
      font-size: 20px !important;
      margin-left: 15px !important;
      margin-top: 20px !important;
      height: 35px !important;
    }
    .info-services {
      .container-img {
        .services-card {
          padding: 30px 10px 30px 10px;
          width: 250px;
          height: 150px;
          gap: 0px 0px !important;

          h2 {
            font-size: 18px !important;
          }

          p {
            font-size: 13px !important;
          }

          .image-services {
            width: 25px;
            height: 25px;
          }
        }
      }
    }
  }

  .nav-bar-page {
    .nav-bar-phone {
      .social-menu-phone {
        .icon-menu {
          .button-navbar-phone {
            width: 45px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .services-container {
    .services-content {
      .services-header {
        height: 420px;
      }
    }
  }

  .feedback-info .container-radio-buttons .group {
    gap: 10px;
  }
}

@media (max-width: 400px) {
  each(range(1%, 100%, 1), {
    .w-xxs-@{index} {
      width: @value;
    }
  });

  .container-img-projects {
    width: 300px;
  }

  .client-pc {
    width: 300px;
  }

  .client-phone {
    width: 50px;
    right: -30px;
    top: 45px;
  }

  .container-image-without-link {
    width: 300px;
    height: 210px;

    .image-without-link {
      width: 180px;
    }
  }

  .container-g360 {
    width: 300px;
    height: 210px;

    img {
      width: 210px !important;
      height: 40px !important;
    }
  }

  .clients-text {
    h1 {
      margin: 0px 0px 0px 10px;
    }
  }

  .client-text {
    a {
      width: 35px;
      height: 15px;
    }
  }

  .services-container-home {
    h1 {
      font-size: 20px;
    }
    .container-img {
      width: 330px;
      .services-card {
        width: 250px;

        h2 {
          font-size: 16px;
        }

        p {
          font-size: 14px;
        }
      }
    }
  }

  #content-video {
    margin-top: calc(15%+20px);
    margin-bottom: 10%;
  }

  .nav-bar-page {
    .nav-bar-phone {
      .social-menu-phone {
        .icons {
          gap: 20px;
        }
      }
    }
  }

  .team-container {
    width: 300px;
  }

  .wrapperCard {
    grid-template-columns: repeat(1, 0fr);
  }
}

@media (max-width: 375px) {
  .form-container {
    width: 92% !important;
  }

  .container-img-projects {
    width: 275px;
  }

  .client-pc {
    width: 275px;
  }

  .client-phone {
    right: -27px;
    top: 40px;
  }

  .container-image-without-link {
    width: 275px;
    height: 190px;

    .image-without-link {
      width: 150px;
    }
  }

  .container-g360 {
    width: 275px;
    height: 190px;

    img {
      width: 150px !important;
    }
  }

  .client-pc {
    width: 275px;
  }

  .client-phone {
    right: -27px;
    top: 40px;
  }

  .container-image-without-link {
    width: 275px;
    height: 190px;

    .image-without-link {
      width: 150px;
    }
  }

  .container-g360 {
    width: 275px;
    height: 190px;

    img {
      width: 150px !important;
    }
  }
}

@media (max-width: 370px) {
  .services-container-home {
    .container-img {
      width: 300px;
      .services-card {
        width: 200px;
        height: 110px;
        gap: 0px;
        padding: 30px 10px;
        h2 {
          position: relative;
          top: -11px;
          font-size: 13px;
        }
        p {
          height: 75px;
          font-size: 11px;
        }

        .image-services {
          top: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .card-who-container {
    width: 220px !important;
    padding: 0px 15px 0px 15px !important;
    height: 260px !important;
  }

  .card-tavuel {
    height: 220px;
  }

  .text-card-tavuel {
    margin-left: 5px;
    p {
      font-size: 11px;
    }
  }

  .team-container {
    width: 220px;
  }

  .wrapperCard {
    grid-gap: 65px 15px;
  }
}

@media (max-width: 350px) {
  .services-container-home {
    .container-img {
      .services-card {
        width: 200px;

        h2 {
          margin: -10px 0px 0px 10px;
        }

        p {
          height: 100px;
        }

        .image-services {
          top: 20px;
          width: 25px;
          height: 25px;
        }
      }
    }
  }
}

@media screen and (max-width: 345px) {
  .container-img-projects {
    width: 250px;
  }

  .client-pc {
    width: 250px;
  }

  .client-phone {
    width: 45px;
  }

  .container-image-without-link {
    width: 250px;
    height: 175px;
  }

  .container-g360 {
    width: 250px;
    height: 175px;
  }
}

@media (max-width: 340px) {
  .services-container-home {
    .container-img {
      width: 280px;
    }
  }

  .feedback-header {
    margin-bottom: 15px;

    h1 {
      margin: 15px 0px 10px 0px;
    }

    h2 {
      margin: 5px 0 0 0;
    }

    .container-close .container button {
      font-size: 20px;
    }
  }

  .feedback-info {
    .textarea-container {
      .textarea-field {
        margin: 8px 0px 0px 0px;
      }
    }
    .container-radio-buttons {
      position: relative;
      top: -10px;
    }
  }

  .send-feedback-container {
    margin-top: 15pxi;
  }
}

@media (max-width: 330px) {
  .info-container {
    .personal-info {
      margin-left: 1px !important;
    }
  }
  .nav-bar-page {
    height: 60px;
    .nav-bar-phone {
      height: 60px;
      .social-menu-phone {
        .icon-menu {
          .button-navbar-phone {
            width: 35px;
            img {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }

  .contact-us-container {
    height: 700px;
  }

  .services-container {
    .services-content {
      .info-service-container {
        .container-img {
          .service-card-info {
            width: 200px;
          }
        }
      }
    }
  }

  .feedback-container {
    bottom: 70px;
    right: 10px;
  }

  .mat-menu {
    top: -10px !important;
  }
}

@media screen and (max-width: 320px) {
  .client-pc {
    width: 220px;
  }

  .client-phone {
    top: 25px;
    right: -10px;
  }

  .container-image-without-link {
    width: 220px;
    height: 155px;

    .image-without-link {
      width: 130px;
    }
  }

  .container-g360 {
    width: 220px;
    height: 155px;

    img {
      width: 130px;
    }
  }

  .services-container {
    .services-content {
      .services-header {
        .text-services {
          p {
            font-size: 12px !important;
          }
          h1 {
            font-size: 18px !important;
          }
        }
      }
    }
  }

  .feedback-info .container-radio-buttons .group {
    flex-wrap: wrap;
  }

  .feedback-info .container-radio-buttons .group mat-radio-button:last-child {
    order: 1;
  }
}

@media screen and (max-width: 300px) {
  .footer {
    .menu-icon {
      font-size: 20px !important;
      margin: 2px;
      height: 15px !important;
      width: 15px !important;
    }
  }

  .services-container-home {
    .container-img {
      width: 250px;
      .services-card {
        width: 180px;
        p {
          height: 85px;
        }
        .image-services {
          right: 20px;
        }
      }
    }
  }
}

@media (max-width: 290px) {
  .services-container-home {
    .container-img {
      width: 230px;
    }
  }
}

@media (max-width: 280px) {
  .nav-bar-page {
    .nav-bar-phone {
      .social-menu-phone {
        .icons {
          gap: 10px;
        }
      }
    }
  }

  .client-phone {
    width: 40px;
    top: 30px;
    right: -8px;
  }
}
.game-section {
  margin-top: 20vh;
  .container {
    margin: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    h2 {
      text-align: center;
      color: #afde02;
      font-size: 3rem;
      margin: 0;
      margin-block: 3px;
      font-family: "tavuel-font-semi-bold" !important;
    }
    .mobile-controls {
      display: none;
      flex: 1;
      .o-pad {
        position: relative;
        width: 200px;
        height: 200px;
        border-radius: 50%;
        overflow: hidden;
        &:after {
          content: "";
          position: absolute;
          z-index: 2;
          width: 20%;
          height: 20%;
          top: 50%;
          left: 50%;
          background: #ddd;
          border-radius: 50%;
          transform: translate(-50%, -50%);
          display: none;
          transition: all 0.25s;
          cursor: pointer;
        }
        &:hover:after {
          width: 30%;
          height: 30%;
        }
        a {
          display: block;
          position: absolute;
          -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
          width: 50%;
          height: 50%;
          text-align: center;
          transform: rotate(45deg);
          border: 1px solid rgba(0, 0, 0, 0.2);
          &:before {
            content: "";
            position: absolute;
            width: 60%;
            height: 60%;
            top: 50%;
            left: 50%;
            background: rgba(255, 255, 255, 0.1);
            border-radius: 50%;
            transform: translate(-50%, -50%);
            transition: all 0.25s;
            cursor: pointer;
            display: none;
          }
          &:after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-radius: 5px;
            border-style: solid;
            transform: translate(-50%, -50%) rotate(-45deg);
            transition: all 0.25s;
          }
        }
        a.up {
          bottom: 50%;
          left: 50%;
          transform: translate(-50%, -20%) rotate(45deg);
          border-top-left-radius: 50%;
          z-index: 1;
          &:hover {
            background: linear-gradient(
              315deg,
              rgba(255, 255, 255, 0) 15%,
              rgba(255, 255, 255, 0.4) 100%
            );
          }
          &:before {
            left: 57%;
            top: 57%;
          }
          &:after {
            left: 53%;
            top: 53%;
            border-width: 0 13px 19px 13px;
            border-color: transparent transparent #aaa transparent;
          }
          &:active:after {
            border-bottom-color: #333;
          }
        }

        a.down {
          top: 50%;
          left: 50%;
          transform: translate(-50%, 20%) rotate(45deg);
          border-bottom-right-radius: 50%;
          z-index: 1;
          &:hover {
            background: linear-gradient(
              135deg,
              rgba(255, 255, 255, 0) 15%,
              rgba(255, 255, 255, 0.4) 100%
            );
          }
          &:before {
            left: 43%;
            top: 43%;
          }
          &:after {
            left: 47%;
            top: 47%;
            border-width: 13px 13px 0px 13px;
            border-color: #aaa transparent transparent transparent;
          }
          &:active:after {
            border-top-color: #333;
          }
        }

        a.left {
          top: 50%;
          right: 50%;
          transform: translate(-20%, -50%) rotate(45deg);
          border-bottom-left-radius: 50%;
          border: none;
          &:hover {
            background: linear-gradient(
              225deg,
              rgba(255, 255, 255, 0) 15%,
              rgba(255, 255, 255, 0.4) 100%
            );
          }
          &:before {
            left: 57%;
            top: 43%;
          }
          &:after {
            left: 53%;
            top: 47%;
            border-width: 13px 19px 13px 0;
            border-color: transparent #aaa transparent transparent;
          }
          &:active:after {
            border-right-color: #333;
          }
        }

        a.right {
          top: 50%;
          left: 50%;
          transform: translate(20%, -50%) rotate(45deg);
          border-top-right-radius: 50%;
          border: none;
          &:hover {
            background: linear-gradient(
              45deg,
              rgba(255, 255, 255, 0) 15%,
              rgba(255, 255, 255, 0.4) 100%
            );
          }
          &:before {
            left: 43%;
            top: 57%;
          }
          &:after {
            left: 47%;
            top: 53%;
            border-width: 13px 0 13px 19px;
            border-color: transparent transparent transparent #aaa;
          }
          &:active:after {
            border-left-color: #333;
          }
        }
        a:hover:after {
          left: 50%;
          top: 50%;
        }
      }
    }

    .score-card {
      padding: 20px;
      color: #d8ebe4;

      .score-board {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .instructions {
          font-family: "tavuel-font-regular";
          text-align: center;
          margin-block: 0px;
          color: #f0f0f0;
        }

        h1 {
          font-family: "tavuel-font-semi-bold";
        }
      }

      .restart-button {
        margin-top: 1rem;
        padding: 10px 20px;
        color: #282846;
        border-radius: 18px;
        height: 40px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #d8ebe4;
        border: none;
        outline: none;
        cursor: pointer;
        font-family: "tavuel-font-semi-bold";
      }
    }

    .game-board {
      background-color: #04275af1;
      width: 80vmin;
      height: 80vmin;
      display: grid;
      align-self: center;
      grid-template-rows: repeat(21, 1fr);
      grid-template-columns: repeat(21, 1fr);

      &.blur {
        filter: blur(4px);
      }
    }
    .scores-container {
      display: flex;
      justify-content: center;
      .scores {
        width: 80vmin;
        display: flex;
        justify-content: space-between;
        p {
          font-family: "tavuel-font-bold";
          font-size: 16px;
          font-weight: 400;
          color: #fff;
        }
      }
    }
  }

  .game-over {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    color: #fed049;
    z-index: 1;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;

    h1 {
      font-size: 3em;
    }
  }
  .snake {
    background-color: #afde02;
    transition: all 0.3ms ease-in;
  }

  .food {
    background: url(https://firebasestorage.googleapis.com/v0/b/tavuel-pwa.appspot.com/o/logo%2FSimbolo_TAVUEL_VERDE.png?alt=media&token=49aea58f-9745-4950-b67e-4c381049e1fc&_gl=1*c59yui*_ga*NzI5ODYxOS4xNjk2ODcxODE3*_ga_CW55HF8NVT*MTY5Njg3MTgxNy4xLjEuMTY5Njg3MTk3Ni4zMy4wLjA);
    background-size: contain;
    background-repeat: no-repeat;
    transition: all 0.3ms ease-in;
  }

  .game-over {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    color: var(--snake-color);
    z-index: 1;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;

    h1 {
      font-size: 3em;
    }
  }

  // Ipads and Mobiles
  @media only screen and (max-width: 1025px) {
    .container {
      flex-direction: column;

      .score-card {
        flex-direction: row !important;
      }
    }
    .mobile-controls {
      margin-top: 10px;
      display: flex !important;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }
}
